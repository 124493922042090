import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de fins a 12 cm de diàmetre, hemisfèric amb el marge enrotllat que després passa a pla. Pot presentar un mamelló. La cutícula és llisa i posteriorment a partir del centre es romp en esquames en forma de metxes de color marró més o manco rogenques. El marge és blanc. Les làmines són primes, atapeïdes i blanques que groguegen amb l’edat. El peu és cilíndric i presenta com una �funda� que envolta el peu des de la base fins a un terç de la seva altura, on s’obri en forma d’anell amb els marges esfilassagats. Per damunt aquest el peu és blanquinós i per davall atigrat del mateix color que el capell. Les espores blanques en massa, són el·líptiques, de 6,5-7,5 x 4,5-5,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      